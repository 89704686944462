import react, { useEffect } from 'react';
import './Friends.scss'; 
import headshot1 from "../assets/headshot1.jpg"; 

function Mentees(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }) 
    return(
        <div className = "friendspage-wrapper">
            <div className = "friendspage-header">
                Our <u>Mentees</u>
            </div>
            <div className = "friendspage-headshots-wrapper">
                <div className = "friendspage-headshots-card">
                    <img src = {headshot1} alt = "Headshot1"></img>
                    <p>John Smith</p>
                </div>
                <div className = "friendspage-headshots-card">
                    <img src = {headshot1} alt = "Headshot1"></img>
                    <p>John Smith</p>
                </div>
                <div className = "friendspage-headshots-card">
                    <img src = {headshot1} alt = "Headshot1"></img>
                    <p>John Smith</p>
                </div>
                <div className = "friendspage-headshots-card">
                    <img src = {headshot1} alt = "Headshot1"></img>
                    <p>John Smith</p>
                </div>
            </div>
            <div className = "friendspage-description">
            Our mentors are drawn from a variety of careers, organizations, and companies, both locally and nationally. We have mentors that work for locally situated, federally funded institutions, such as <a href = "https://www.jlab.org/" target = "_blank">Jefferson Lab</a>, <a href = "https://www.nasa.gov/langley/" target = "_blank">NASA Langley Research Center</a>, U.S. Army Corps of Engineers, US Army Research Laboratory, Institute for Defense Analyses, National Ground Intelligence Center, Aurora Flight Sciences/Boeing, US Naval Research Laboratory; for leading international companies like <a href = "https://www.honeywell.com/us/en" target = "_blank">HoneyWell</a> and <a href = "https://www.micron.com/" target = "_blank">Micron</a> for leading Virginia-based companies and corporations, such as <a href = "https://lunainc.com/" target = "_blank">Luna Innovations</a>, <a href = "https://www.mitre.org/" target = "_blank">Mitre</a>, and <a href = "https://www.ivwatch.com/" target = "_blank">ivWatch</a>; and for technology companies like <a href = "https://www.tiktok.com/en/" target = "_blank">TikTok</a> and <a href = "https://petdx.com/" target = "_blank">PetDx</a>. Many of our mentors are alumnae of the physics, mathematics or computer science departments at William & Mary.
            </div>
        </div>
    )
}

export default Mentees; 