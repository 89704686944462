import React from 'react'; 
import program from "./dec_9_2022_mcp_programv2.png"; 
import seating from "./dec_9_2022_mcp_seating_arrangement_v2.png"

function Dec_9_2022(props){
	return(
		<div className = "event">
			<img alt = "MCP program" src = {program}/> 
			<br/> 
			<img alt = "MCP seating arrangement" src = {seating}/> 
		</div> 
	)
}

export default Dec_9_2022; 