import React, { Fragment } from 'react';
import "./FancyLink.scss";

function FancyLink(props) {
    return (
        <Fragment>
            <div style={{
                width: props.width,
                height: props.height,
                margin: props.margin,
                top: props.top,
                bottom: props.bottom


            }} className="fancy-link-wrapper">
                <a href={props.link} target="_blank" rel="noreferrer">
                    {props.text}
                    <div style={{
                        transform: `translate(-${props.width}, 0)`,
                        transition: `0.2s ease-in-out`,
                    }} className="fancy-link-bar">
                    </div>
                </a>
            </div>
        </Fragment>
    )
}

export default FancyLink; 