import React, { Fragment, useState } from 'react';
import { Link, NavLink } from "react-router-dom";


import './navbar.scss';


export default function Navbar(props) {


    const [isTransparent, setisTransparent] = useState(true);

    const handleScroll = () => {
        if ((window.scrollY < 10)) {
            setisTransparent(true);
        } else {
            setisTransparent(false)
        }
    }

    const [active, setActive] = useState("nav__menu");
    const [icon, setIcon] = useState("nav__toggler");
    const navToggle = () => {
        if (active === "nav__menu") {
            setActive("nav__menu nav__active");
        } else setActive("nav__menu");

        // Icon Toggler
        if (icon === "nav__toggler") {
            setIcon("nav__toggler toggle");
        } else setIcon("nav__toggler");
    };


    window.addEventListener("scroll", handleScroll)
    return (
        <Fragment>

            <nav className={isTransparent ? "navbar transparent" : "navbar nontransparent"}>

                <div className="logo-wrapper" >
                    <Link to="/"><h1>MCP</h1></Link>
                </div>
                <div className="menu-container" id="navbarSupportedContent">
                    <ul className={active}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="https://give.wm.edu/?a=38ff2762-682c-497f-b540-f77eebc77831&d=5519" target="_blank" rel="noopener noreferrer" onClick={navToggle}> Giving </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/news" onClick={navToggle}> News </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/mentors" onClick={navToggle}> Mentors </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact" onClick={navToggle}> Contact </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/portal/login" onClick={navToggle}> Sign Up </NavLink>
                        </li>
                    </ul>
                </div>
                <div className={icon} onClick={navToggle}>
                    <div className="hamline line1" style={{ border: "1px solid" }}></div>
                    <div className="hamline line2" style={{ border: "1px solid", marginTop: "3px" }}></div>
                    <div className="hamline line3" style={{ border: "1px solid", marginTop: "3px" }}></div>
                </div>

            </nav>


        </Fragment>
    )
}




