import React, { useState } from 'react';
import PropTypes, { nominalTypeHack } from 'prop-types'
import "./NewsArticle.scss";
import { formatDate } from '../helpers/Formatting';
import { marked } from 'marked';

function NewsArticle(props) {

    marked.setOptions({
        gfm: true,
        tables: true,
        breaks: false,
        pedantic: false,
        smartLists: true,
        smartypants: false
    });
    const [articleBody, setArticleBody] = useState(marked.parse(props.body));

    return (
        <div className="newsarticle-wrapper">
            <div className='newsarticle-xbtn' onClick={props.close}>
                <div className='newsarticle-xbtn-line1' id="newsarticle-xbtn-line"></div>
                <div className='newsarticle-xbtn-line2' id="newsarticle-xbtn-line"></div>
            </div>
            <div className="newsarticle-subject">{props.subject}</div>
            {props.date ? <div className="newsarticle-date">{formatDate(props.date)}</div> : null}


            {(props.firstName && props.lastName && props.contactEmail) ?
                <div className="contactmessage-header-wrapper">
                    <div className="contactmessage-name">From: {props.firstName} {props.lastName} [{props.contactEmail}] </div>
                    {props.contactSubject ? <div className="contactmessage-contactSubject">Subject: {props.contactSubject}</div> : null}
                </div>
                : null
            }


            {props.body ? <div className="newsarticle-body" dangerouslySetInnerHTML={{ __html: articleBody }}></div> : null}


        </div>

    )
}

NewsArticle.propTypes = {
    date: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string
}

export default NewsArticle