import React, { useState, useRef } from 'react';
import './BioPopup.scss';
import PropTypes from 'prop-types';
import { FaLinkedin } from 'react-icons/fa';



function BioPopup(props) {
	const popupRef = useRef(null);
	const cardRef = useRef(null);

	function handleShow() {
		if (props.longBio && props.hasBio) {
			popupRef.current.className = "biopopup-popup biopopup-popup-show"
		}
		else if (props.linkedin) {
			window.open(props.linkedin, '_blank');
		}
	}
	function handleClose() {
		popupRef.current.className = "biopopup-popup"
	}
	function handleHover() {
		if (!props.longBio && props.hasBio) {
			popupRef.current.className = "biopopup-slideup biopopup-slideup-show"
			cardRef.current.className = "biopopup-card biopopup-card-hide"
		}
	}
	function handleNoHover() {
		if (!props.longBio && props.hasBio) {
			popupRef.current.className = "biopopup-slideup"
			cardRef.current.className = "biopopup-card"
		}
	}


	// will either show or hide the popup window 

	const baseStyle = {
		width: props.width,
	}


	//split into 2 things: card and the popup window that will render over everything 
	return (
		<div className="biopopup-wrapper" style={baseStyle}>
			<div className="biopopup-card-wrapper">
				<div className={(props.hasBio || props.linkedin) ? "biopopup-card biopopup-card-bio" : "biopopup-card"}
					ref={cardRef}
					style={props.cardStyles ? props.cardStyles : null}
					onClick={handleShow}
					onMouseOver={handleHover}
					onMouseLeave={handleNoHover}>
					<img src={props.image} /> <br />
					<h1>{props.name}</h1>
					{props.subheading ? <h2>{props.subheading}</h2> : null}
				</div>
				{!props.longBio && props.hasBio ?
					<div ref={popupRef} className="biopopup-slideup"
						style={props.slideupStyles ? props.slideupStyles : null}
						onMouseOver={handleHover}
						onMouseLeave={handleNoHover}>
						{props.body}

					</div>
					: null}
			</div>

			{props.longBio ?
				<div ref={popupRef} className="biopopup-popup"
					style={props.popupStyle ? props.popupStyle : null}>
					<div className="close" onClick={handleClose}>&#10005;</div>
					<img src={props.image} /> <br />
					<h1>{props.name}{props.subheading ? ", " + props.subheading : null} </h1>
					<p>{props.body}</p>
					{props.linkedin ? <a href={props.linkedin} target="_blank"><FaLinkedin fontSize="1.4em" /></a> : null}

				</div>
				:
				null
			}

		</div>
	)

}

BioPopup.propTypes = {
	cardStyles: PropTypes.object, //customize how the card will look 
	slideupStyles: PropTypes.object, //customize the slidup (if bio is not long)
	popupStyles: PropTypes.object, // customize how the popup window will look 
	width: PropTypes.string.isRequired,
	hasBio: PropTypes.bool,
	longBio: PropTypes.bool.isRequired,
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	linkedin: PropTypes.string,
	subheading: PropTypes.string,
	body: PropTypes.string.isRequired
}

BioPopup.defaultProps = {
	hasBio: true,
	longBio: true,
}

export default BioPopup;