


export async function sendRequest(request){
    const url = request.url;
    const req = {
        redirect: "follow", 
        method: request.method, 
        code: 'cors',
        headers: request.headers,
        body: JSON.stringify(request.body)
    }
    const response = await fetch(url, req).then(response => {
        if (response.redirected){
            window.location.href = response.url; 
        }
        return response 
    })
    return response; 
}

export function getCookie(cname){
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}



export async function sendAuthedRequest(request){
    /*
        For protected API routes. 
        Will attach an accessToken to the request
        

        Will deal with outdated access tokens
    */
    request.headers['x-auth-token'] = getCookie("accessToken"); 
    const response = await sendRequest(request); 
    if(response.ok){ //anything in the range 200-299
        return response 
    }

    if(response.status === 401) { //outdated auth token --> request accesstoken 
        const aresponse = await fetch("/api/v1/auth/getAccessToken")
        if(!aresponse.ok){
            window.location.href("/portal/login")
        }
        const aresult = await aresponse.json()
        setCookie("accessToken", aresult['accessToken'], 10);
        setCookie("refreshToken", aresult['refreshToken'], 10);
        const response = await sendRequest(request); 
        if(response.ok){ //anything in the range 200-299
            return response 
        }
    }
    else{ //send the request again 
        const response = await sendRequest(request); 
        if(response.ok){ //anything in the range 200-299
            return response 
        }
    }
}
