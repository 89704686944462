import React, {Fragment, useEffect, useState} from "react";
import Footer from "../components/Footer"
import { sendRequest } from "../../helpers/SendRequests";
import CircleBtn from "../components/CircleBtn";
import NewsArticle from "../../components/NewsArticle";
import {Link} from 'react-router-dom'; 
import ReactPlayer from "react-player"

import BioPopup from "../components/BioPopup"; 


//images
import chrisheadshot from "../assets/monahan-headshot.jpg";
import ranheadshot from "../assets/ran-headshot.jpg"; 
import jasonheadshot from '../assets/jason-headshot.jpeg';
import elisabethheadshot from '../assets/elisabeth-headshot.jpg';
import so1918banner from '../assets/So1918_banner.png'
import placeholdernewsimage  from "../assets/blank_background.png";
import brewer_headshot from "./home/assets/brewer_headshot.png";
import dorand_headshot from './home/assets/JED_Headshot_2023.jpg'; 
import gates_headshot from './home/assets/gates_headshot.png'; 
import philips_headshot from './home/assets/philips_headshot.png'; 


import "./home.scss"; 

function Home(){
    const PeopleState = Object.freeze({
        "MENTORS":0, "FRIENDS":1, "TEAM":2}); 
    const [peopleState, setPeopleState] = useState(PeopleState.MENTORS); 
    const [darkMode, setDarkMode] = useState(false); 
    const [newsArticles, setNewsArticles] = useState([]); 
    const [curArticleState, setCurArticleState] = useState({});
    const [showArticle, setShowArticle] = useState(false); 
    const [isFlipped, setIsFlipped] = useState(false);
      
    const handleButtonClick = () => {
        setIsFlipped(!isFlipped);
    };

    const reveal = () => {
        if(window.location.pathname == "/"){ //Only want this for the landing page  
            var reveals = document.querySelectorAll(".img"); 
            for (var i = 0; i < reveals.length; i++) {
                var windowHeight = window.innerHeight;
                var elementTop = reveals[i].getBoundingClientRect().top;
                var elementVisible = 50;
            
                if (elementTop < windowHeight - elementVisible) {
                  reveals[i].classList.add("active");
                }
              }
              if((document.getElementById("news").getBoundingClientRect().top) < window.innerHeight - 0.4*window.innerHeight){
                  setDarkMode(true);
                }else{
                  setDarkMode(false); 
              }
        }
    }
    window.addEventListener("scroll", reveal); 



    //Functions for controlling news articles
    async function handleNewsClick(id){
        //get the article information 
        const state = await sendRequest({
            url: `/api/v1/news/article/${id}`,
            method: 'GET'
        })

        setCurArticleState(await state.json())
        setShowArticle(true); 
    }
    function closeArticle(){
        setShowArticle(false); 
    }

    //On initial load, get news articles from backend 
    //if no connection, then newsArticles are just an empty array 
    //--> renders nothing 
    useEffect(() => { 
        window.scrollTo(0, 0);
        const getNewsArticles = async () => {
            let response = await sendRequest({
                url: '/api/v1/news/titles',
                method: 'GET', 
            })
            response = await response.json();
            if(response.length > 5){
                setNewsArticles(response.slice(response.length-5, response.length))
            }else{

                setNewsArticles(response); 
            }
        }
        getNewsArticles(); 
    }, [])

    return (
        <Fragment>
            <div className={darkMode ? "home-wrapper dark" : "home-wrapper light"}>
                <div className="header-wrapper" id="home" >
                    <div>
                        <div className="wave"></div>
                        <div className="wave"></div>
                        <div className="wave"></div>
                    </div>
                    <div className="stars">
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                    </div>
                    <h3 className="underlined underlined--gradient">
                        Mentoring For Careers In Physics <br></br>
                        <a href="/interest">
                            <button className="button-glow">Interest form</button>
                        </a>
                    </h3>

                </div>
                <hr className = "titleline"/>

                <div className= "mission-story-wrapper" id="mission-story">
                    <div className={isFlipped ? "front" : "back"}>
                        <div className="misson-story-inner">
                            <div className="mission-wrapper" id="mission">
                                <div className="mission-header-text">
                                    Our <u>Mission</u>
                                </div>
                                <div className="mission-text">
                                    <p>Mentoring for Careers in Physics provides one-on-one professional mentoring for undergraduate students majoring in physics, or thinking about careers in STEM (Science, Technology, Engineering, and Mathematics). Our mentors work with students to cultivate a rewarding and educational experience by sharing their professional insights and experiences, providing career guidance and networking opportunities, and helping students identify and plan their future STEM careers. We welcome undergraduate students from gender minorities in physics.
                                    </p>
                                </div>
                            </div>
                            <div className="story-wrapper" id="story">
                                <div className="story-header-text">
                                    Our <u>Story</u>
                                </div>
                                <div className="story-text">
                                    MCP was founded by Ran Yang and Chris Monahan, inspired by several students’ experience “…the accommodations and support you displayed towards our class meant the world to me and very much helped <b>solidify my feeling secure as a woman in the field</b>”.
                                    Our <a href="https://youtu.be/TqgStDcsD7g" target="_blank" rel="noreferrer">pilot project</a> launched on December 10, 2021.
                                </div>
                            </div>
                        </div>
                        <button onClick={handleButtonClick} className="flip-button">
                            Flip
                        </button>
                    </div>
                    <div className="video-wrapper">
                        <ReactPlayer className="story-video" light="https://wmit-news-prod.s3.amazonaws.com/wp-content/uploads/2022/10/06095008/mentor_lead.jpg" url="https://youtu.be/TqgStDcsD7g" playing="true" width="100%" height="100%"/>
                    </div>
                    
                </div>
                <hr />

                <div className = "people-wrapper" id = "people">
                    <div className="people-header">
                        Our <u>People</u>
                    </div>
                    <div className = "people-menu-wrapper">
                        {/* <p onClick = {() => setPeopleState(PeopleState.MENTEES)} 
                        className = {peopleState === PeopleState.MENTEES ? "menu-item active": "menu-item inactive"}>Mentees</p> */}

                        <p onClick = {() => setPeopleState(PeopleState.MENTORS)} 
                        className = {peopleState === PeopleState.MENTORS ? "menu-item active": "menu-item inactive"}>Mentors</p>

                        <p onClick = {() => setPeopleState(PeopleState.FRIENDS)} 
                        className = {peopleState === PeopleState.FRIENDS ? "menu-item active": "menu-item inactive"}>Our Friends</p>

                        <p onClick = {() => setPeopleState(PeopleState.TEAM)} 
                        className = {peopleState === PeopleState.TEAM ? "menu-item active": "menu-item inactive"}>Our Team</p>
                    </div>

                    <div className = "people-card-wrapper">
                    <div className = {peopleState === PeopleState.MENTORS? "text-wrapper active" : "text-wrapper inactive"}>
                        <div className = "card-wrapper">
                            <div className = "mentors-card-wrapper">
                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "250px"
                            height = "400px"
                            longBio = {true} 
                            image = {brewer_headshot}
                            name = "Janet Brewer"
                            subheading = "Chief People Officer, Arcfield"
                            body = "
                                As chief people officer at Arcfield, Janet Brewer is responsible for developing and executing the company’s people- and marketing-related initiatives including organizational development, talent acquisition, total rewards, employee experience, diversity, equity and inclusion, and all internal and external marketing and communications activities. Janet has more than 20 years of experience in global human resources, leading large teams and managing business transformation through integration and scaling for growth. Throughout her career, her focus has been on maximizing the impact of companies, by effectively leveraging the power of communications, HR and technology to achieve sustainable business results. She is passionate about empowering people to perform at their best and love what they do every day.

                                    Prior to joining Arcfield, Janet was the vice president of human resources at Peraton, where she oversaw a 50-person team responsible for strategic human capital consulting and employee relations. Janet also held various roles of increasing responsibility at SAP, including SAP Concur and SAP SuccessFactors, as well as working at the company’s global headquarters in Germany for two years as an expat. Earlier in her career, Janet managed leadership development programs and delivered technical training at Freddie Mac, Experian and MicroStrategy. Janet earned a bachelor’s degree in physics from the College of William and Mary, a master’s degree in biomedical engineering from the University of Michigan, and an MBA from Marymount University."
                            />
                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "250px"
                            height = "400px"
                            longBio = {true} 
                            image = {philips_headshot}
                            name = "Julia M. Phillips"
                            subheading = " Sandia National Laboratories, Retired"
                            body = "
                                Julia M. Phillips retired from Sandia National Laboratories in 2015. She culminated her Sandia career by serving as Vice President and Chief Technology Officer. Prior to her time at Sandia, she spent 14 years at AT&T Bell Laboratories where she performed leading edge research in thin film epitaxial electronic materials and complex oxides. She received a B.S. degree in physics from the College of William and Mary and a Ph.D. in applied physics from Yale University. Phillips is a member of the National Academy of Engineering (NAE) and fellow of the American Academy of Arts and Sciences, Materials Research Society, American Association for the Advancement of Science (AAAS), and the American Physical Society (APS). She received the George E. Pake Prize from APS “for her leadership and pioneering research in materials physics for industrial and national security applications.” She has authored more than 100 journal publications and holds five patents. Phillips was appointed a member of the National Science Board in 2016 and reappointed to the class of 2022-2028."
                            />
                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "250px"
                            height = "400px"
                            longBio = {true} 
                            image = {dorand_headshot}
                            name = "Dr. Jennifer Dorand"
                            subheading = "Senior Medical Physicist, ProCure"
                            body = "
                                Dr. Jennifer Dorand graduated from William and Mary in 2009 with a BS in physics. During her time at William and Mary, she discovered the American Association of Physicists in Medicine (AAPM) and her future career, medical physics, through a Google search and participated in developmental biology and biophysics research. She received her doctoral degree in physics at Wake Forest University in 2014. While there, she had the opportunity to explore the clinical and academic aspects of medical physics, participating in research and quality assurance in the Department of Radiation Oncology. Her doctoral work focused on the development and characterization of a Sr-90 irradiation device for the study of cutaneous radiation injury following a radiological incident and has led to continued efforts to develop radiation countermeasures for cutaneous radiation injury. Following her graduate studies, Jennifer completed a fellowship in medical physics at the Mayo Clinic. While completing her clinical training, she pursued various clinical projects, most notably the commissioning and clinical implementation of a novel imaging modality for image-guided radiation therapy. She received her board certification from the American Board of Radiology in 2018. Jennifer now works as a senior medical physicist at ProCure, a standalone proton radiation therapy clinic, in Somerset, New Jersey. Her desire to help others extends outside of medical physics to patient advocacy for those suffering from inflammatory bowel disease (IBD). She currently serves on the Patient Governance Committee for IBD Partners, a patient-powered research network, and is a patient co-investigator on a project examining the comparative effectiveness of biologic and small molecule therapies in IBD."
                            />

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "250px"
                            height = "400px"
                            longBio = {true} 
                            image = {gates_headshot}
                            name = "Evalyn Gates"
                            subheading = "Theoretical Physicist"
                            body = "
                                Evalyn Gates is a theoretical physicist and author who has spent most of her research career deep in the cosmos, happily searching for dark matter, dark energy, neutrinos and white dwarfs. She is also passionate about inviting every curious mind on planet Earth to explore the natural world around us, from our own backyards to the most distant galaxies -- and beyond. Her first book, Einstein’s Telescope: The Hunt for Dark Matter and Dark Energy in the Universe, was published by W.W. Norton in February 2009.
                                Her trek through the academic world began at the College of William & Mary where she did her undergraduate work in physics, followed by an interesting detour through biomedical engineering. Unable to resist the call of the subatomic world, she dove back into physics -- earning her Ph.D. at CWRU before moving on to postdoctoral fellowships at Yale University and the University of Chicago, where she eventually joined the research faculty in the Department of Astronomy & Astrophysics and the Kavli Institute for Cosmological Physics.
                                Gates has served as the Assistant Director of the Kavli Institute for Cosmological Physics at the University of Chicago; Executive Director & CEO of the Cleveland Museum of Natural History; and Vice President for Science & Education at Chicago's Adler Planetarium & Astronomy Museum. She is currently an Adjunct Professor of Physics at CWRU and serving on several committees and advisory panels working to address the underrepresentation of women and minorities in science, recognizing that we are missing the ideas, energy and creativity of too many talented individuals who should be encouraged and supported.
                                Evalyn and her husband Eric have three grown children and live in the Rocky Mountains of Colorado where they spend as much time as possible hiking, skiing and exploring this incredible wilderness. She has summited 9 of Colorado's 14ers ... and counting."
                            />
                            </div>

                        </div> 
                        <div className = "company-logos">
                                
                        </div> 
                        <div className = "mentors-link">
                            <Link to = "/mentors" className = "button">
                                <span className="button__text">All Mentors</span>
                            </Link>
                        </div> 
                    </div>
                    {/* <div className = {peopleState === PeopleState.MENTEES? "text-wrapper active" : "text-wrapper inactive"}>
                        <p>
                        Our mentees are William & Mary undergraduate students from gender minorities in physics, who are either majoring in physics or considering physics as a major.
                        </p>
                    </div> */}
                        <div
                            className={peopleState === PeopleState.TEAM ? "card-wrapper active" : "card-wrapper inactive"}>
                            <div className="team-card-wrapper">
                                <BioPopup
                                    cardStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    slideupStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    width="250px"
                                    height="400px"
                                    longBio={false}
                                    image={ranheadshot}
                                    name="Ran Yang"
                                    subheading=""
                                    body="Professor in the Physics Department and a co-founder and director of MCP."
                                />

                                <BioPopup
                                    cardStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    slideupStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    width="250px"
                                    height="400px"
                                    longBio={false}
                                    image={chrisheadshot}
                                    name="Chris Monahan"
                                    subheading=""
                                    body="Assistant Professor in the Physics Department and a co-founder and director of MCP."
                                />

                                <BioPopup
                                    cardStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    slideupStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    width="250px"
                                    height="400px"
                                    longBio={false}
                                    image={jasonheadshot}
                                    name="Jason LaPierre"
                                    subheading=""
                                    body="Jason ('25) is the MCP web developer. He is studying biology and computer science at W&M."
                                />

                                <BioPopup
                                    cardStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    slideupStyles={{
                                        backgroundColor: "rgb(255, 255, 250)"
                                    }}
                                    width="250px"
                                    height="400px"
                                    longBio={false}
                                    image={elisabethheadshot}
                                    name="Elisabeth Everhart"
                                    subheading=""
                                    body="Elisabeth ('27) is the program assistant and PR coordinator for MCP. She is studying physics-EPAD and computer science at W&M."
                                />
                            </div>
                            <div className="mentors-link">
                                <Link to="/retired" className="button">
                                    <span className="button__text">All Previous Team Members</span>
                                </Link>
                            </div>
                        </div>
                        <div
                            className={peopleState === PeopleState.FRIENDS ? "text-wrapper active" : "text-wrapper inactive"}>
                            <p>MCP is supported by the <a href="https://www.wm.edu/as/physics/" target="_blank" rel="noreferrer"> Physics Department</a> and <a href = "https://advancement.wm.edu/volunteer-leadership/society-of-1918/about/index.php" target = "_blank" rel="noreferrer">The Society of 1918</a>.
                            We are collaborating with Professor Pamela Eddy and Ph.D. student Jenny Carlos of the School of Education. 
                            The MCP website is made possible by a generous IDEA grant from the Diversity Endowment through the Office of Diversity & Inclusion.
                        </p>
                        <img width = "300px" src = {so1918banner} alt = "Society of 1918 banner"></img> 

                    </div>
                    </div>
                </div>
                <hr />


                <div className = "news-wrapper" id = "news">
                    <div className="news-header">
                            <Link to = "/news">Latest <u>Insights</u></Link>
                    </div>
                    <div className = "news-cards-wrapper">
                        {
                            newsArticles.slice().reverse().map((item) => {
                                //place default image if there is no image to be found 
                                if(item.imageUrl === undefined){
                                    item.imageUrl = placeholdernewsimage; 
                                }
                                return (
                                    <div key = {item.id} style = {{backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 30%, rgba(0,0,0,0) 100%), url(${item.imageUrl})`}} className = "news-card" onClick = {() => handleNewsClick(item.id)}>
                                        <div className = "news-card-header">
                                            {item.subject}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
                {showArticle ? <NewsArticle 
                            subject = {curArticleState.subject} 
                            date = {curArticleState.date}
                            body = {curArticleState.body}
                            close = {closeArticle}
                            /> : null }
                {/* <div className="contact-wrapper" id="contact">
                    <CircleBtn id="contact-btn" darkMode={darkMode} to="/contact" width="220px" height="220px" fontStyle="normal"
                        fontSize="18px"
                        text="Contact Us" />
                </div> */}
                <Footer/>
            </div>  

        </Fragment>
    )
}

export default Home; 