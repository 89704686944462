import React, { Fragment } from 'react';
import "./Login.scss"


export default function PortalLogin() {

    async function handleLogin() {
        // need to redirect the user to the http://localhost:<API port>/api/v1/login 
        // need to know if this is in developer mode or not. If in dev mode, there will be different ports for the client and the API 

        let redirectRoute = window.location.origin + "/portal/dashboard"
        let apiPort = ""
        if (process.env.NODE_ENV !== undefined && process.env.NODE_ENV === "development") {
            apiPort = ":5050"
        }
        window.location.href = window.location.protocol + "//" + window.location.hostname + apiPort + "/api/v1/auth/login?redirectTo=" + redirectRoute

    }

    async function handleRegister() {
        // need to redirect the user to the http://localhost:<API port>/api/v1/register 
        // need to know if this is in developer mode or not. If in dev mode, there will be different ports for the client and the API 

        let redirectRoute = "https://docs.google.com/forms/d/e/1FAIpQLScgoW6JoTv9knGPWRN5Pg-_evza8vcQrLXKzRdNxVr7XDnaOw/viewform?usp=sf_link";

        // Redirect the user to the Google Form URL
        window.open(redirectRoute, "_blank");
        // let redirectRoute = window.location.origin + "/portal/register"
        // let apiPort = ""
        // if (process.env.REACT_APP_DEVELOPER_MODE !== undefined && process.env.REACT_APP_DEVELOPER_MODE === "True") {
        //     apiPort = ":5000"
        // }
        // window.location.href = window.location.protocol + "//" + window.location.hostname + apiPort + "/api/v1/auth/login?redirectTo=" + redirectRoute
    }

    return (
        <Fragment>

            <div className="portal-login-wrapper">
                <div className="portal-login-header">MCP Portal</div>
                <button onClick={() => handleLogin()}>Login using CAS</button>
                <button onClick={() => handleRegister()}>Need an account? Register here</button>
            </div>
        </Fragment>
    )
}