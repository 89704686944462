import React, { useEffect, useState, useRef, Fragment } from 'react';
import './News.scss';
import NewsArticle from '../../components/NewsArticle';
import { sendRequest } from '../../helpers/SendRequests';
import { formatDate } from '../../helpers/Formatting';
import { PortalAPI } from '../../api/portal';
import Alert from '../components/Alert';

//if props.portal is true, then get all portal articles 
function News(props) {
    const [curArticleState, setCurArticleState] = useState({
        subject: "",
        date: "",
        body: "",
    })
    const [isPortal, setIsPortal] = useState(props.portal ? true : false)
    const [showArticle, setShowArticle] = useState(false);
    const [articles, setArticles] = useState([]);
    const alert = useRef(null)

    async function handleClick(id, articleIsClearweb) {
        //get the article information 
        let status = null; let response = null;
        let portalAPI = new PortalAPI();
        if (!articleIsClearweb) {
            [status, response] = await portalAPI.getPortalArticle(id);
        } else {
            [status, response] = await portalAPI.getArticle(id);

        }
        if (status === 200) {
            setCurArticleState(response)
            setShowArticle(true);
        } else {
            console.log("Error loading the message")
        }

    }
    function closeArticle() {
        setShowArticle(false);
    }


    useEffect(async () => {
        window.scrollTo(0, 0);
        const portalApi = new PortalAPI();

        const getArticleTitles = async () => {
            let status = null; let response = null;
            if (isPortal) {
                [status, response] = await portalApi.getPortalArticleTitles();
            } else {
                [status, response] = await portalApi.getArticleTitles();
            }
            if (status == null || status != 200) {
                console.log("Error getting the article titles")
            }
            setArticles(response);
        };

        if (isPortal) {
            //decompose the response from the server 
            const [articlestatus, articlejson] = await portalApi.getPortalArticleTitles();
            if (articlestatus === 200) {
                setArticles(articlejson)
            } else {
                alert.current.update("Error getting news articles.")
                alert.current.setWarningLevel(2);
            }
        } else {
            getArticleTitles();

        }
    }, []);



    return (
        <Fragment>
            <Alert ref={alert} />
            <div className="newspage-wrapper"
                id={props.portal ? 'newspage-wrapper-portal' : ""}
            >

                {showArticle ? <NewsArticle
                    subject={curArticleState.subject}
                    date={curArticleState.date}
                    body={curArticleState.body}
                    close={closeArticle}
                /> : null}

                <div className='newspage-header'>Latest <u>Insights</u></div>
                <div className="newspage-news-wrapper" id="newspage-news-wrapper">
                    {
                        articles.slice().reverse().map((item, i) => {
                            return (
                                <div key={item.id} className="newspage-news-article" onClick={() => handleClick(item.id, item.isClearweb)}>
                                    <h3>{item.subject}</h3>
                                    <p>{formatDate(item.date)}</p>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default News; 
