import React, {Fragment, useRef, useState} from 'react'; 
import PropTypes from 'prop-types'

import "./ToolTip.scss"; 

function ToolTip(props){
	const [show, setShow] = useState(false); 
	const bodyRef = useRef(null); 

	async function handleShow(){
		if(!show){
			bodyRef.current.className = "tooltip-body tooltip-body-show"
		}else{
			bodyRef.current.className = "tooltip-body"
		}
		await setShow(!show); 
		
	}
	function handleClose(){
		setShow(false); 
		bodyRef.current.className = "tooltip-body"; 
	}

	return(
		<span className = "tooltip-component-wrapper" onClick = {handleShow}>
			<button onClick = {handleShow}>?</button> 
			<div ref = {bodyRef} className = "tooltip-body" >
				<p>{props.body}</p> <a onClick = {handleClose}>&#10005;</a>
			</div> 
		</span> 
	)
}
ToolTip.propTypes = {
    body: PropTypes.string,
}


export default ToolTip