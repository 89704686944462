import React, {useState, useRef} from 'react'; 
import Alert from '../../../../clearweb/components/Alert';
import { PortalAPI } from '../../../../api/portal';

const UploadUserInformation = (props) => {
    const [file, setFile] = useState(); 
    const [type, setType] = useState("mentee"); 
    const [edit, setEdit] = useState("create"); 
    const [uploadState, setUploadState] = useState("Upload")
    const alert = useRef(null); 

    function handleUpload(event){
        //check if the file is .csv 
        if(event.target.files[0].type !== "text/csv"){
            //throw error 
            alert.current.update("Please upload a .csv file.")
            alert.current.setWarningLevel(2)
            return; 
        }
        setFile(event.target.files[0])
        alert.current.update(`File ${event.target.files[0].name} selected`)
        alert.current.setWarningLevel(0); //green alert 
    }
    async function handleSubmit(){
        // Create a form data object to send to the server 
        // { 'File': <file data> }
        setUploadState("Uploading...")
        const fileReader = new FileReader(); 
        fileReader.onload = async (event) => { //waits for the filereader to get called (readAsText)
            let file_data = event.target.result 
            const PortalApi = new PortalAPI(); 
            //send authed request
            const [resstatus, resjson] = await PortalApi.postUserCsv(file_data, type, edit)

            
            if(resstatus !== 200){
                alert.current.setWarningLevel(2) //error 
                alert.current.setTimeout(15000) // 
            }else{
                alert.current.setWarningLevel(0) //success
            }
            alert.current.update(resjson.msg)
            setUploadState("Upload")
        }
        await fileReader.readAsBinaryString(file)
                
    }
    return (
        <div className = "admin-function-wrapper">
            <Alert ref = {alert}/> 
            <h1>Upload user information</h1>
            <input type = "file" onChange={handleUpload}/>
            { file ? 
                (<button onClick = {handleSubmit}>{uploadState}</button>)
                : 
                (null)}
            <span className = "uploadUserInfo-type-radio">
                <p>Type of users</p> 
                <input type = "radio" value = "mentee" name = "type" 
                    checked = {type === "mentee"}
                    onChange = {(e) => setType("mentee")} /> Mentee
                <input type = "radio" value = "mentor" name = "type" 
                    checked = {type === "mentor"}
                    onChange = {(e) => setType("mentor")} /> Mentor 
            </span> 
            <span className = "uploadUserInfo-edit-radio">
                <p>Type of users</p> 
                <input type = "radio" value = "Edit" name = "edit" 
                    checked = {edit === "edit"}
                    onChange = {(e) => setEdit("edit")}/> Edit User Profiles
                <input type = "radio" value = "Create" name = "edit" 
                    checked = {edit === "create"}
                    onChange = {(e) => setEdit("create")}/> Create User Profiles 
            </span>
            <br/> <br/> 

            <p>Please provide .csv file formatted as follows. An example .csv file can be found <a href = "/api/v1/admin/examples/upload_user_data_example.csv">here.</a> The csv file must contain email, firstName, and lastName fields.</p>
            <p>Header row:</p> 
            <pre> 
                ..., email, firstName, lastName, ...
            </pre>
            <p>Data rows:</p>
            <pre>
                ..., user@wm.edu, John, Smith, ... 
            </pre>
        </div>
    )
}

export default UploadUserInformation; 