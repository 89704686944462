import { JsonWebTokenError } from 'jsonwebtoken';
import { sendAuthedRequest, sendRequest } from '../helpers/SendRequests.js';
export class PortalAPI {

	async getUser(profile) {
		const req = {
			url: `/api/v1/protectedusers/profile/${profile}`,
			method: "GET",
			headers: {},

		}
		const response = await sendAuthedRequest(req);
		const resJson = await response.json();
		return [response.status, resJson]; //ES6 array packing
	}

	async updateUser(profile) {
		//construct the body of the request (uses fetch)
		const req = {
			url: '/api/v1/protectedusers/updateprofile',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: profile
		}
		const response = await sendAuthedRequest(req);
		const resJson = await response.json();
		return [response.status, resJson]
	}

	async updateUserResponses(question_ids, responses) {
		// saves the responses to the questions the user had 
		const req = {
			url: '/api/v1/questions',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: {
				question_ids,
				responses
			}
		}
		const response = await sendAuthedRequest(req);
		const resJson = await response.json();
		return [response.status, resJson];
	}

	async getPortalArticleTitles() {
		const req = {
			url: '/api/v1/protectednews/portal/titles',
			method: 'GET',
			headers: {}
		}
		const response = await sendAuthedRequest(req);
		const resJson = await response.json();
		return [response.status, resJson];
	}

	async getPortalArticle(id) {
		const req = {
			url: `/api/v1/protectednews/portal/article/${id}`,
			method: 'GET',
			headers: {}
		}
		const response = await sendAuthedRequest(req);
		const resJson = await response.json();
		return [response.status, resJson];
	}

	async getArticleTitles() {
		const req = {
			url: `/api/v1/news/titles`,
			method: 'GET'
		}
		const response = await sendRequest(req);
		const resJson = await response.json();
		return [response.status, resJson];
	}

	async getArticle(id) {
		const req = {
			url: `/api/v1/news/article/${id}`,
			method: 'GET'
		}
		const response = await sendRequest(req);
		const resJson = await response.json();
		return [response.status, resJson];
	}


	async healthCheck() {
		const req = {
			url: "/api/v1/health",
			method: "GET"
		}
		const response = await sendRequest(req);
		const resjson = await response.json();
		return [response.status, resjson]
	}

	// ------- Admin functions ----------

	async getUserResponses(user_type) {
		/**
		 * Returns a CSV file of mentee responses 
		 */
		const req = {
			url: `/api/v1/admin/userresponses/${user_type}`,
			method: 'GET',
			headers: {}
		}
		const response = await sendAuthedRequest(req);
		return response;

	}

	async postUserCsv(file_data, type, edit) {
		/**
		 * Uploads a .csv file of user data, server will parse it and add users to the database 
		 */
		const req = {
			url: `/api/v1/admin/upload_user_data/${type}/${edit}`,
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			body: {
				file_data
			}
		}
		const response = await sendAuthedRequest(req);
		const resjson = await response.json();
		return [response.status, resjson];
	}

	async getSysViewableUsers() {
		const req = {
			url: "/api/v1/protectedusers/sys_viewable_profiles",
			method: "GET",
			headers: {},
		}
		const response = await sendAuthedRequest(req);
		const resjson = await response.json();
		return [response.status, resjson];
	}



}


