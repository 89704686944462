import React, {Fragment, useState, useEffect, useRef} from 'react'; 
import PropTypes from 'prop-types';
import { PortalAPI } from '../../api/portal';
import Alert from "../../clearweb/components/Alert"
import {getState, setState} from '../../state/StateManagement.js'; 
import { getCookie } from '../../helpers/SendRequests';
import QuestionComponent from "./Profile_components/question_component.js"
import ToolTip from "../../components/ToolTip"; 
import "./Profile.scss"


function Profile(props){
    // need this to keep track of what fields the user has changed 
    //performs a deep copy of the profile 
    const [profileEdited, setProfileEdited] = useState(false); 
    const [profile, setProfile] = useState(new Map()); //stores profile information 

    // list of questions the user can answer 
    const [profileQuestions, setProfileQuestions] = useState([])
    const alert = useRef(null)

    useEffect(async () => {
        //query the associates api and get the name of the associates from this user 
        let profile = props.profile; 

        setProfile(profile)
        //get profile questions from the API 
        let request_type = "mentor"
        if(profile.status === "MENTEE" || profile.status === "MENTEE_PENDING"){
            request_type = "mentee"
        }
        
        // get the type of questions based on if the user is a mentor or mentee  
        const qresponse = await fetch("/api/v1/questions/"+request_type)
        if (!qresponse.ok){
            alert.current.update("There was an issue getting data from the server.")
            alert.current.setWarningLevel(2) 
        }else{
            let questions = await qresponse.json() 
            setProfileQuestions(questions)
        }

    }, [])

    async function handleSave(){
        // fetch a put request to the API that will save the changes to the user's profile 
        //need to re-construct a user's profile object to send back to the server 
        const portalAPI = new PortalAPI(); 

        const [status, message] = await portalAPI.updateUser(profile)
        if(status !== 200){
            alert.current.update("Error updating profile.")
            alert.current.setWarningLevel(2)
            return; //don't continue to updating the user's questions since there was a problem with the server 
        }

        //success updating all aspects of the profile 
        alert.current.update("Updated profile.")
        alert.current.setWarningLevel(0)
        setProfileEdited(false) //hide the cancel and save buttons 
        
        
    }

    // handles when a user reponds to a profile question 
    function handleQuestionReponse(res, question){
        // appends the question modified 
        let response = {
            in_response_to: question._id, 
            response: res 
        }
        // try to find the response - if its there, then modify it  
        let responses = profile.profileQuestionResponses; 
        let modified = false; 
        for(let i = 0; i<responses.length; i++){
            if(responses[i].in_response_to == response.in_response_to){
                responses[i] = response 
                modified = true; 
                break; 
            }
        } 
        if(!modified){ //not there - add this new question to the list of responded to questions
            responses.push(response); 
        }
        // update the questions that have been modified by the user 
        setProfile({...profile, profileQuestionResponses: responses}); 
        setProfileEdited(true); 
    }

    return(
        <Fragment>
            <Alert ref = {alert} />
            <div className = "portal-profile-wrapper">
                <div className = "portal-your-info-wrapper">
                    <h1>Your Info</h1>

                    <span>
                        <b>First Name </b>
                        <input type = "text" 
                        onChange = {function (e){ 
                            setProfile({...profile, firstName: e.target.value})
                            setProfileEdited(true)
                        }}
                        value = {profile.firstName ? profile.firstName: "" }></input>
                    </span>                                                          
                    <span>
                        <b>Last Name </b>
                        <input type = "text" 
                        onChange = {function (e){ 
                            setProfile({...profile, lastName: e.target.value})
                            setProfileEdited(true)
                        }}
                        value = {profile.lastName ? profile.lastName: "" }></input>
                    </span> 
                    <p><b>Status</b> {profile.status ? profile.status : ""}</p>
                    <span>
                        <b>Public Viewable <ToolTip body = "Let your info be publicly shown?"/></b>
                        <input type = "checkbox" 
                        checked = {profile.publicViewable ? profile.publicViewable : false}
                        onChange = {function (e){
                            setProfile({...profile, publicViewable: !profile.publicViewable});
                            setProfileEdited(true)}}
                        ></input>
                    </span>
                    <span>
                        <b>System Viewable <ToolTip body = "Display your info to other MCP users?"/></b> 
                        <input type = "checkbox" 
                        checked = {profile.systemViewable ? profile.systemViewable : false}
                        onChange = {function (e){
                            setProfile({...profile, systemViewable: !profile.systemViewable});
                            setProfileEdited(true)}}
                        ></input>
                    </span>
                    <span>
                        <b>Job Title </b>
                        <input type = "text" 
                        onChange = {function (e){
                            setProfile({...profile, jobTitle: e.target.value});
                            setProfileEdited(true)}}
                        value = {profile.jobTitle ? profile.jobTitle : ""}></input>
                    </span>

                    <span>
                        <b>Employer </b>
                        <input type = "text" 
                        onChange = {function (e){
                            setProfile({...profile, employer: e.target.value});
                            setProfileEdited(true)}}
                        value = {profile.employer ? profile.employer : ""}></input>
                    </span>

                    <span>
                        <b>LinkedIn </b>
                        <input type = "text" 
                        onChange = {function (e){
                            setProfile({...profile, linkedin: e.target.value});
                            setProfileEdited(true)}}
                        value = {profile.linkedin ? profile.linkedin:  ""}></input>
                    </span>

                    {/**
                     * Will automatically determine if it needs to display mentor or mentee 
                     */}
                    <p><b>{
                    profile.status ? 
                        profile.status === "MENTEE" || profile.status === "MENTEE_PENDING"? 
                            "Mentor" : "Mentee(s)"
                    : ""}</b> {
                    profile.associates && profile.associates.length > 0 ? 
                        profile.associates.join(', ') 
                        : "N/A"}</p>

                </div>
                <div className = "portal-profile-questions">
                    <h1>Your Questions</h1>

                    { profileQuestions ? 
                        profileQuestions.map((item, idx) => <QuestionComponent key = {idx} number = {idx + 1} question = {item} profile = {profile ? profile : null} responseHandler = {handleQuestionReponse}/> ) 
                    : null } 
                </div>
                {profileEdited ?
                <Fragment> 
                <div className = "profile-save-button" onClick = {() => handleSave()}>
                    Save Changes
                </div> 
                </Fragment>
                : null}
                
            </div>
        </Fragment>
    )

}

export default Profile 