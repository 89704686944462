import React from 'react'; 
import {useParams} from 'react-router-dom'; 
import './Events.scss';
import Dec_9_2022 from "./event_components/dec_9_2022.js"; 


function Events(props){

	const {eventName} = useParams(); 
	console.log(eventName); 

	//render components based on the eventName 

	return (
		<div className = "events-wrapper">
			{ eventName === "12-9-22" ? <Dec_9_2022/> : null}
		</div> 
	)
}

export default Events; 