import React, {Fragment} from 'react'; 
import UserReponses from "./components/userResponses"
import UploadUserInformation from './components/uploadUserInfo'
import "./AdminFunctions.scss"

const AdminFunctions = (props) => {
    return(
        <div className = "portal-adminfunctions-wrapper">
            <UserReponses/>
            <UploadUserInformation/> 

        </div> 
    )
}

export default AdminFunctions; 