import React, {Fragment, useEffect, useState} from 'react'; 
import "./ViewProfiles.scss"
import { PortalAPI } from '../../api/portal';

export default function ViewProfiles(){
    const [users, setUsers] = useState([]); 
    const [loaded, setLoaded] = useState(false); 

    useEffect(async () => {
        const portalAPI = new PortalAPI(); 
        const [response, res_json] = await portalAPI.getSysViewableUsers(); 
        setUsers(res_json.sys_view_users); 
        setLoaded(true); 

    }, [])

    function formatStatus(status){
        switch(status){
            case "SUPERADMIN":
                return "Superadmin"; 
            case "ADMIN": 
                return "Admin"; 
            case "MENTEE_PENDING":
                return "New Mentee"; 
            case "MENTOR_PENDING":
                return "New Mentor"; 
            case "MENTOR":
                return "Mentor"; 
            case "Mentee":
                return "Mentee"; 
            default: 
                return status.charAt(0) +status.slice(1).toLowerCase()

        }
    }

    return (
        <div className = "view-profiles-wrapper">
            <h1> Others in the MCP Program: </h1> 
            <div className = "view-profiles-component-wrapper">
                {!loaded ? 
                    <h1>Fetching users...</h1> : null}
                {(loaded && (users.length > 0)) ? 
                 users.map((user, idx) => {
                    return (
                        <div key = {idx} className = "view-profiles-profile">
                            <h2>{user.firstName[0]}</h2> 
                            <h3>{user.firstName} {user.lastName}</h3> 
                            <p>{formatStatus(user.status)}</p>
                            
                        </div>
                        )
                 })
                : 
                <h1>There are no users that you can see</h1> }
            </div>
        </div>
    )
}