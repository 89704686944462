function getAllState(){
	for(var a in window.localStorage){
		console.log(a, " : ", window.localStorage[a]); 
	}
}

function getState(stateName){
	return window.localStorage.getItem(stateName); 
}

function addState(stateName, stateValue){
	window.localStorage.setItem(stateName, stateValue); 
}

function setState(stateName, stateValue){
	window.localStorage.setItem(stateName, stateValue); 
}

function deleteAllState(){
	window.localStorage.clear(); 
}

module.exports = {getAllState, getState, addState, setState, deleteAllState}