import React, { useEffect } from 'react';
import './Friends.scss';


import BioPopup from "../components/BioPopup";
import juliaheadshot from "../assets/julia-headshot.jpg";
import minheadshot from "../assets/min-headshot.jpg";
import sophiaheadshot from "../assets/sophia-headshot.jpg";
import pieroheadshot from "../assets/piero-headshot.jpeg";
import adi_headshot from "../assets/blank-profile.jpeg";
import carsonheadshot from "../assets/carson-headshot.jpg";



function RetiredMembers(){
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return(
        <div className = "friendspage-wrapper">
            <div className = "friendspage-header">
                Our previous <u>Team Members</u>
            </div>
            <div className = "friendspage-headshots-wrapper">
                <BioPopup
                    cardStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width = "250px"
                    height = "400px"
                    longBio = {false}
                    image = {juliaheadshot}
                    name = "Julia Merti"
                    subheading = ""
                    body = "Julia ('24) was the Program Assistant for MCP and graduated with a Bachelor of Science in Physics-EPAD."
                />

                <BioPopup
                    cardStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width = "250px"
                    height = "400px"
                    longBio = {false}
                    image = {minheadshot}
                    name = "Min Kim"
                    subheading = ""
                    body = "Min ('24) was the web developer for MCP. He graduated with a degree Computer Science and Economics at W&M."
                />

                <BioPopup
                    cardStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width = "250px"
                    height = "400px"
                    longBio = {false}
                    image = {sophiaheadshot}
                    name = "Sophia Chen"
                    subheading = ""
                    body = "Sophia ('24) was the social media designer and PR coordinator for MCP. She studied Data Science at W&M."
                />

                <BioPopup
                    cardStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width = "250px"
                    height = "400px"
                    longBio = {false}
                    image = {pieroheadshot}
                    name = "Piero Panariello"
                    subheading = ""
                    body = "Piero ('23) was the formal web developer for MCP. He is studying computer science at W&M and works part-time for NASA."
                />

                <BioPopup
                    cardStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width = "250px"
                    height = "400px"
                    longBio = {false}
                    image = {adi_headshot}
                    name = "Adi de la Guardia"
                    subheading = ""
                    body = "Adi ('23) was the formal Director of Public Relations for MCP. She is a physics-EPAD major and Art minor."
                />

                <BioPopup
                    cardStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles = {{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width = "250px"
                    height = "400px"
                    longBio = {false}
                    image = {carsonheadshot}
                    name = "Carson Stillman"
                    subheading = ""
                    body = "Former MCP program assistant. Carson is in the Ph.D. program of the Computer Information Sciences and Engineering department at the University of Florida."
                />

            </div>
        </div>
    )
}

export default RetiredMembers;