import React from 'react'; 
import "./CircleBtn.scss"; 
import {Link} from 'react-router-dom'; 

function CircleBtn(props){
    var fontsize = '15px'; 
    if(props.fontSize){
        fontsize = props.fontSize; 
    }
    var fontstyle= 'italic'; 
    if(props.fontStyle){
        fontstyle = props.fontStyle; 
    }
    const style = {
        fontStyle: fontstyle, 
        width: props.width,
        height: props.height, 
        textDecoration: 'none', 
        fontSize: fontsize
    }
    return(
        <Link 
            id = {props.id}
            className = {props.darkMode ? "circlebtn-wrapper dark": "circlebtn-wrapper light"} 
            style = {style}
            to = {props.to}
            >
                <p>{props.text}</p>
        </Link>
    )
}

export default CircleBtn; 