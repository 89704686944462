import React, {Fragment, useState, useEffect, useRef}from "react"; 
import "./Register.scss"
import Alert from "../../clearweb/components/Alert"
export default function PortalRegister(){

    const [status, setStatus] = useState("MENTEE_PENDING") 
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")

    const alert = useRef(null)

    useEffect(() => { //check if the user has logged in using CAS before allowing the user to view the page. 
        //if the user is not loggedin, send them to the CAS login 
        async function checkUser(){
            const response = await fetch("/api/v1/auth/checkcas")
            if(!response.ok){ // if the response is bad, then redirect the user to cas login 
                let redirectRoute = window.location.origin + "/portal/register"
                let apiPort = ""
                if(process.env.NODE_ENV !== undefined && process.env.NODE_ENV === "development"){
                    apiPort = ":5050"
                }
                window.location.href = window.location.protocol + "//" + window.location.hostname + apiPort + "/api/v1/auth/login?redirectTo=" + redirectRoute
            }
        }
        checkUser();  

    })

    async function handleSubmit(){
        const response = await fetch(`/api/v1/auth/register/${fName}/${lName}/${status}`); 
        if(response.ok){
            window.location.replace("/portal/dashboard")
        }else{
            const json = await response.json()
            alert.current.update(json['msg'])
            alert.current.setWarningLevel(2)
        }
    }
    

    return(
        
        <Fragment> 
            <Alert ref = {alert} /> 
            <div className = "register-page-wrapper">
                <h1> Register an account with us.</h1> 
                <div className = "register-form">
                    <p>First Name</p><input type = "text" onChange = {(e) => setFName(e.target.value)}/>
                    <p>Last Name</p><input type = "text" onChange = {(e) => setLName(e.target.value)}/>
                    <p>What do you want to be? <br/> <span style = {{fontSize: "12px", fontStyle: "italic"}}>Select Mentee if you are a William and Mary student looking for a mentor. <br/> Select Mentor if you are a mentor looking to be paired with a student.</span></p>
                    <select onChange = {(e) => setStatus(e.target.value)}name = "status-select" id = "status-select">
                        <option value = "MENTEE_PENDING">Mentee</option>
                        <option value = "MENTOR_PENDING">Mentor</option>
                    </select>
                </div>
                <div className = "submitbutton">
                    <button onClick = {() => handleSubmit()}>Finish</button>
                </div>
            </div>
        </Fragment> 
    )
}