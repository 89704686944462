import React, { Fragment, useEffect, useState, useRef } from "react";
import {PortalAPI} from "../../api/portal"
import "./Associate.scss"; 
import Alert from "../../clearweb/components/Alert"


export default function Associate(props){

    //get the associate information from the api 
    const [associates, setAssociates] = useState([]) //empy array of associate profiles 
    const [noAssociates, setNoAssociates] = useState(false); 
    const alert = useRef(null)


    useEffect(async () => {
        //wait for the user profile to not be null 
        let portalapi = new PortalAPI(); 

        //handle the associates that the user has -- 
        //1) no associates - show error message 
        //2) there are associates - loop over each associate and append their profile information to the end of the associate array 
        setAssociates([]); //set to blank array - added to after api query 
        if(props.profile.associates.length ==0) {setNoAssociates(true)}
        else{
            for(let i = 0; i<props.profile.associates.length; i++){
                const [status, response]= await portalapi.getUser(props.profile.associates[i]); 
                if(status === 200){
                    setAssociates(current => [...current, response])
                }else{
                    alert.current.update("There was an error getting the associates from the server.")
                    alert.current.setWarningLevel(2)
                }
            }

        }
    }, [])

    return( 
        <Fragment>
            <Alert/>
            <div className = "associate-component-wrapper">
                {noAssociates && props.profile.status === "MENTOR_PENDING"? <h1>Your account is still pending. Wait for the administrators approve your account.</h1>:null}
                {noAssociates && props.profile.status === "MENTEE_PENDING"? <h1>Your account is still pending. Wait for the administrators approve your account.</h1>:null}
                {noAssociates && props.profile.status === "MENTOR"? <h1>No mentees have been assigned to you yet. Wait for the administrators to give you one.</h1>:null}
                {noAssociates && props.profile.status === "MENTEE"? <h1>No mentor has been assigned to you yet. Wait for the administrators to give you one.</h1>:null}
                
                {associates.map((associate, index) => {
                    return <div key = {index} className = "portal-associates-associate-profile-wrapper">
                        <h3>{associate.firstName} {associate.lastName}</h3>
                        {associate.email ? <p><b>Email</b> {associate.email}</p> : null}
                        {associate.linkedin && associate.linkedin != ""? <p><b>Linkedin</b> {associate.linkedin}</p> :null}

                        {associate.jobTitle && associate.jobTitle != ""? <p><b>Job Title</b> {associate.jobTitle}</p> : null } 
                        {associate.employer && associate.employer != "" ? <p><b>Employer</b> {associate.Employer}</p> : null } 
                        {associate.links && associate.links.length > 0 ? <p><b>Links</b> {associate.links}</p> : null } 
                    </div>
                })}
            </div> 
        </Fragment>
    )
}