import React , {Fragment} from 'react'
import './userResponses.scss'
import { PortalAPI } from '../../../../api/portal'
const download = require('downloadjs')

const UserResponses = (props) => {
    async function handleDownloadUserResponses(user_type){
        const portalAPI = new PortalAPI(); 
        const data = await portalAPI.getUserResponses(user_type); 
        const blob = await data.blob(); 
        //get timestamp 
        const date = new Date(); 
        download(blob, `${user_type} responses ${date.getMonth()}-${date.getDate()}-${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}.csv`)


    }


    return (
        <div className = "admin-function-wrapper"> 
            <h1> Download user responses in CSV format</h1>
            <button onClick = {() => handleDownloadUserResponses("mentee")}>Mentee Responses</button>
            <button onClick = {() => handleDownloadUserResponses("mentor")}>Mentor Responses</button>
            <button onClick = {() => handleDownloadUserResponses("admin")}>Admin Responses</button>
        </div>
    )
}
export default UserResponses