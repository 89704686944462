import React, {Fragment, useState} from 'react'; 
import './Alert.scss'

/**
 * Use case: 
 * import it to a react functinoal component 
 * import useRef from React 
 * Create reference next to where state variables woudl go const alert = useRef(null)
 * Insert the component into the html code <Alert ref = {alert} /> 
 * Call from anywhere in the code alert.current.update(str)  and   alert.current.setWarningLevel(int)  <- takes in integer from 0 to 2 
 * 	0 being green, 2 being red 
 */

const Alert = React.forwardRef((props, ref) => {
	const warning = [
	"rgba(0,200,0,0.9)",
	"rgba(200, 200, 200, 0.9)",
	"rgba(230, 0, 0, 0.9)"
	]
	const [open, setOpen] = useState(false); 
	const [body, setBody] = useState(""); 
	const [warningNum, setWarningNum] = useState(0); 
	const [time, setTime] = useState(5000)
	let timer; 

	React.useImperativeHandle(ref, () => ({
		update(text){
			window.clearTimeout(timer); 
			setBody(text); 
			setOpen(true); 
			timer = window.setTimeout(setOpen, time, false); 
		},
		setWarningLevel(warning){
			setWarningNum(warning); 
		},
		setTimeout(length){
			setTime(length); 
		}
	}))


	return(
		<Fragment>
			<div style = {{backgroundColor: warning[warningNum]}} className = {open ? "alert-wrapper alert-wrapper-open" : "alert-wrapper alert-wrapper-closed"}>
				<p className= "alert-body">
					{body}
				</p>
				<button onClick = {() => {setOpen(false)}}className = "alert-close">	
&#10005;</button> 
			</div> 
		</Fragment> 
	)
})

export default Alert; 