import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types'; 
import "./question_component.scss"; 

export default function QuestionComponent(props){

    // need to match the question id with the proper response from the profile 
    const [response, setResponse] = useState(""); 
    useEffect( () => {
        // loop through the profile responses and see if there are any matches, if there are
        // then load the component with that response 
        // if not, then intiialize to a blank response 
        if(props.profile != null){
            for(let i = 0; i<props.profile.profileQuestionResponses.length; i++){
                if(props.profile.profileQuestionResponses[i].in_response_to === props.question._id){

                    setResponse(props.profile.profileQuestionResponses[i].response)
                    return 
                }
    
            }
            setResponse(""); // response must be null 
       }
    }, [props.profile])


    return(
        <div className = "portal-profile-question">
            <b>{props.number}. {props.question.question_message}</b>

            { props.question.question_type === "TEXT"? 
            
            <input value = {response} 
                    type = "text" onChange = {function(e){
                        setResponse(e.target.value); 
                        props.responseHandler(e.target.value, props.question)
                    }}/>

            :null }

            { props.question.question_type === "DROPDOWN" ? 
            <select 
                    onChange = {function(e){
                        setResponse(e.target.value); 
                        props.responseHandler(e.target.value, props.question); 
                    } }> 
            {props.question.options.map((item, idx) => {
                if(response === item){
                    return <option selected key = {idx} value = {item}>{item}</option>
                }else{
                    return <option  key = {idx} value = {item}>{item}</option>
                }
            })}
            </select>

            :null}

            { props.question.question_type === "MULTISELECT" ? 
            // multiple checkboxes with options for each one 

            //parse the value of the response 
                props.question.options.map((item, idx) =>  
                    <div key = {idx} className = "multiselect-item">

                    <input type = "checkbox" key = {idx} checked = {response.split("%%").includes(item)}
                    value = {item} onChange = {function(e){
                            //if not in response, then add it to response
                            let selections = response.split("%%"); 

                            selections = selections.filter(word => word.length > 0); //remove any blanks 


                            if(selections.includes(item)){
                                selections = selections.filter(word => word !== item); 
                            }
                            else{
                                selections.push(item); 
                                
                            }                            
                            setResponse(selections.join("%%")) // concatinates as a strings separated by commas

                            props.responseHandler(selections.join("%%"), props.question); 

                        }}/>
                        <label>{item}</label>
                        
                    </div>

                )
            
            
                :null }

        </div> 
    )
}

QuestionComponent.propTypes = {
    // question with fields: 
    // question_message : string 
    // question_type: string 
    // options: array of strings 
    profile: PropTypes.object.isRequired, 
    responseHandler: PropTypes.func.isRequired //Requires a function that will hande the user response 
}