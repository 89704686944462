import React, {Fragment, useState, useRef, useEffect} from 'react'; 
import './Contact.scss'; 
import {sendRequest} from "../../helpers/SendRequests"; 
import Alert from '../components/Alert'; 

export default function Contact(){
	const [fname, setFName] = useState(""); 
	const [lname, setLName] = useState(""); 
	const [subject, setSubject] = useState(""); 
	const [email, setEmail] = useState(""); 
	const [body, setBody] = useState("");
	const [alertMsg, setAlertMsg] = useState(""); 
	const [alertId, setAlertId] = useState(0); 

	const alert = useRef(null); 

	useEffect(() => {
		window.scrollTo(0, 0);
	})


	async function handleSubmit(){
		if(fname === "" || lname === "" || subject === "" || email === "" || body === ""){
			alert.current.setWarningLevel(2); 
			alert.current.update("Please fill out the entire form."); 
		}else{
			//form data is good to go. send to server 
			const msg = await sendRequest({
				url: "/api/v1/contact", 
				method: "POST", 
				headers: {"Content-Type": "application/json"},
				body: {
					firstName: fname, 
					lastName: lname, 
					subject: subject, 
					email: email, 
					message: body
				}
			})
			const msgJson = await msg.json(); 
			if(msg.status === 200){
				alert.current.setWarningLevel(0); 
				 
			}else{
				alert.current.setWarningLevel(2); 
			}
			alert.current.update(msgJson.msg);

		}	

		


	}
	return(
		<Fragment>
			<div className = "contact-wrapper">
				<div className = "contact-header">Contact <u>Us</u> <p>Email: <a href = "mailto:mcp.superuser@gmail.com" target = "_blank">mcp.superuser@gmail.com</a></p></div> 
				<div className = "contact-form-wrapper">
					<div className = "input-wrap input-fname">
						<label className = "contact-lbl" htmlFor = "name">First Name:</label> 
						<input name = "fname" className = "form-input input-small" type = "text" onChange = {(e) => setFName(e.target.value)} value = {fname}/>
					</div>
					<div className = "input-wrap input-lname">
						<label className = "contact-lbl" htmlFor = "lname">Last Name:</label> 
						<input name = "lname" className = "form-input input-small" type = "text" onChange = {(e) => setLName(e.target.value)} value = {lname}/>
					</div>
					<div className = "input-wrap input-email">
						<label className = "contact-lbl" htmlFor = "email">Email:</label> 
						<input name = "email" className = "form-input input-big" type = "text" onChange = {(e) => setEmail(e.target.value)} value = {email}/>
					</div>
					<div className = "input-wrap input-subject">
						<label className = "contact-lbl" htmlFor = "subject">Subject:</label> 
						<input name = "subject" className = "form-input input-big" type = "text" onChange = {(e) => setSubject(e.target.value)} value = {subject}/>
					</div>
					<div className = "input-wrap input-msg">
						<label className = "contact-lbl" htmlFor = "body">Your Message:</label> 
						<textarea name = "body" className = "form-input input-area" type = "text" onChange = {(e) => setBody(e.target.value)} value = {body}/>
					</div>
					<button className = {"contact-submit-btn btn-nosuccess"} onClick = {() => handleSubmit()}>Send</button>


				</div> 
				<Alert ref = {alert}/> 
			</div> 
		</Fragment> 
	)
	
}

