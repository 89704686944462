import React, { useEffect } from 'react';
import './Friends.scss'; 
import blank_profile from "../assets/blank-profile.jpeg"
import karen from "../assets/Karen_Taminger-square-headshot.jpg"; 
import kristina from "../assets/Kristina_Kruglyak-square-headshot.jpg"
import amber from "../assets/Amber_Ulbrich-square-headshot.jpg"; 
import evalyn from "../assets/Evalyn_Gates-square-headshot.jpg"; 
import holly from "../assets/Holly_Szumila-Vance-square-headshot.jpg"; 
import kelly from "../assets/Kelly_Berns-square-headshot.jpg"; 
import liz from "../assets/Liz_Ward-square-headshot.jpg"; 
import melissa from "../assets/Melissa_Dolph-square-headshot.jpg"; 
import brown_headshot from "../assets/Marisa_Brown-square-headshot.jpg"; 
import aye from "./home/assets/Aye_Myat_headshot.jpg"
import yulia from "./home/assets/Yulia_Furletova_headshot.jpg"
import brewer_headshot from "../assets/CTFV--Janet86782.png"; 
import dorand_headshot from './home/assets/JED_Headshot_2023.jpg'; 
import gates_headshot from './home/assets/gates_headshot.png'; 
import philips_headshot from './home/assets/philips_headshot.png'; 
import ward_headshot from "./home/assets/ward_headshot.png"; 
import xu_headshot from "../assets/Yang_Xu_headshot.jpg"; 
import arcfield from "./home/assets/Arcfield.png";
import athena from "./home/assets/Athena_Theatrical.png";
import bae from "./home/assets/BAE.png";
import calspan from "./home/assets/Calspan.png";
import case_western from "./home/assets/Case_western.png";
import defense_analysis from "./home/assets/Defense_analysis.png";
import devcom from "./home/assets/Devcom.png";
import dod from "./home/assets/DOD.jpg";
import fanniemae from "./home/assets/FannieMae.png";
import honeywell from "./home/assets/Honeywell.png";
import ivwatch from "./home/assets/ivwatch.png";
import jefferson_lab from "./home/assets/Jefferson_Lab.png";
import lunalabs from "./home/assets/Lunalabs.png";
import micron from "./home/assets/Micron.png";
import microsoft from "./home/assets/Microsoft.png";
import mitre from "./home/assets/Mitre.jpg";
import nasa from "./home/assets/NASA.jpg";
import nasa2 from "./home/assets/NASA.png";
import naval_research_lab from "./home/assets/Naval_Research_Lab.png";
import procure from "./home/assets/Procure.png";
import psionic from "./home/assets/Psionic.jpg";
import sandia from "./home/assets/Sandia.jpg";
import srnl from "./home/assets/SRNL.png";
import ting from "./home/assets/Ting.png";
import marlynAndino from "./home/assets/mentors/MarlynAndino.jpeg"
import brandiClark from "./home/assets/mentors/BrandiClark.jpeg"
import carrieRhoades from "./home/assets/mentors/CarrieRhoades.jpeg"
import gabrielleJawer from "./home/assets/mentors/GabrielleJawer.jpeg"
import jenniferInman from "./home/assets/mentors/JenniferInman.jpeg"
import kristenBishop from "./home/assets/mentors/KristenBishop.jpeg"
import mystiqueOwens from "./home/assets/mentors/MystiqueOwens.jpeg"
import sandraPhilpott from "./home/assets/mentors/SandraPhilpott.jpeg"
import sanghwaPark from "./home/assets/mentors/SanghwaPark.jpeg"
import vanessaAubuchon from "./home/assets/mentors/VanessaAubuchon.jpeg"
import brendaVanMill from "./home/assets/mentors/BrendaVanMil.jpeg"


import BioPopup from "../components/BioPopup"; 



function Mentors(){
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return(
        <div className = "friendspage-wrapper">
            <div className = "friendspage-header">
                Our <u>Mentors</u>
            </div>
            <div className = "friendspage-headshots-wrapper">
                            <BioPopup 
                                cardStyles = {{
                                    backgroundColor: "rgb(255, 255, 250)"
                                }}
                                slideupStyles = {{
                                    backgroundColor: "rgb(255, 255, 250)"
                                }}
                                width = "220px"
                                height = "400px"
                                longBio = {true} 
                                hasBio = {true}
                                image = {kelly}
                                name = "Kelly Berns"
                                subheading = "Advanced Cyber Security Engineer, Honeywell"
                                body = "After originally pursuing a business degree and a career in sales, I quickly learned that I was interested in something more technical. I began exploring a few options and immediately fell in love with cyber security. I developed a strong sense of passion and had tunnel vision to forge my career in this field. Since then, I have worked as a penetration tester at Honeywell. The most important quality for any engineer is to have a sense of curiosity, I love to see this working with my mentee."
                            /> 
                            <BioPopup 
                                cardStyles = {{
                                    backgroundColor: "rgb(255, 255, 250)"
                                }}
                                slideupStyles = {{
                                    backgroundColor: "rgb(255, 255, 250)"
                                }}
                                width = "220px"
                                height = "400px"
                                longBio = {true} 
                                image = {brewer_headshot}
                                linkedin="https://www.linkedin.com/in/brewerjanet/"
                                name = "Janet Brewer"
                                subheading = "Chief People Officer, Arcfield"
                                body = "
                                    As chief people officer at Arcfield, Janet Brewer is responsible for developing and executing the company’s people- and marketing-related initiatives including organizational development, talent acquisition, total rewards, employee experience, diversity, equity and inclusion, and all internal and external marketing and communications activities. Janet has more than 20 years of experience in global human resources, leading large teams and managing business transformation through integration and scaling for growth. Throughout her career, her focus has been on maximizing the impact of companies, by effectively leveraging the power of communications, HR and technology to achieve sustainable business results. She is passionate about empowering people to perform at their best and love what they do every day.
                                    Prior to joining Arcfield, Janet was the vice president of human resources at Peraton, where she oversaw a 50-person team responsible for strategic human capital consulting and employee relations. Janet also held various roles of increasing responsibility at SAP, including SAP Concur and SAP SuccessFactors, as well as working at the company’s global headquarters in Germany for two years as an expat. Earlier in her career, Janet managed leadership development programs and delivered technical training at Freddie Mac, Experian and MicroStrategy. Janet earned a bachelor’s degree in physics from the College of William and Mary, a master’s degree in biomedical engineering from the University of Michigan, and an MBA from Marymount University."/>

                            <BioPopup 
                                cardStyles = {{
                                    backgroundColor: "rgb(255, 255, 250)"
                                }}
                                slideupStyles = {{
                                    backgroundColor: "rgb(255, 255, 250)"
                                }}
                                width = "220px"
                                height = "400px"
                                longBio = {true} 
                                image = {brown_headshot}
                                linkedin="https://www.linkedin.com/in/marisabrown/"                                name = "Marisa Brown"
                                subheading = "Senior Biomedical Engineer, ivWatch LLC"
                                body = "Since 2017, Marisa has helped design optical biosensors and monitoring systems that detect IV infiltration as a part of the R&D team at ivWatch. She also runs clinical investigations at the company and is an ACRP Certified Professional. In her free time, Marisa enjoys learning through podcasts and encouraging students at William & Mary to reach their full potential."
                            />

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {melissa}
                            linkedin="https://www.linkedin.com/in/melissa-commisso-dolph-9b637729/"
                            name = "Melissa Dolph"
                            subheading = "Principle Engineer, MITRE"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {true} 
                            image = {dorand_headshot}
                            name = "Jennifer Dorand"
                            subheading = "Senior Medical Physicist, ProCure Treatment Centers, Inc"
                            body = "
                            Jennifer Dorand graduated from William and Mary in 2009 with a BS in physics. During her
                            time at William and Mary, she discovered the American Association of Physicists in
                            Medicine (AAPM) and her future career, medical physics, through a Google search and
                            participated in developmental biology and biophysics research. She received her doctoral
                            degree in physics at Wake Forest University in 2014. While there, she had the opportunity
                            to explore the clinical and academic aspects of medical physics, participating in research
                            and quality assurance in the Department of Radiation Oncology. Her doctoral work focused
                            on the development and characterization of a Sr-90 irradiation device for the study of
                            cutaneous radiation injury following a radiological incident and has led to continued efforts
                            to develop radiation countermeasures for cutaneous radiation injury. Following her
                            graduate studies, Jennifer completed a fellowship in medical physics at the Mayo Clinic.
                            While completing her clinical training, she pursued various clinical projects, most notably
                            the commissioning and clinical implementation of a novel imaging modality for imageguided radiation therapy. She received her board certification from the American Board of
                            Radiology in 2018. Jennifer now works as a senior medical physicist at ProCure, a standalone proton radiation therapy clinic, in Somerset, New Jersey. In addition to her work in the
                            clinic, she serves on multiple AAPM committees, including the Women’s Professional
                            Subcommittee and the Working Group on Equity, Diversity, and Inclusion Committee
                            Outreach and Social Media. Her desire to help others extends outside of medical physics to
                            patient advocacy for those suffering from inflammatory bowel disease (IBD). "
                            />

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {yulia}
                            linkedin="https://www.linkedin.com/in/julia-furletova-83330531/"
                            name = "Yulia Furletova"
                            subheading = "Nuclear Physicist, Jefferson Lab"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {true} 
                            image = {gates_headshot}
                            linkedin="https://www.linkedin.com/in/evalyn-gates/"
                            name = "Evalyn Gates"
                            subheading = "Adjunct Professor Manager, Case Western University"
                            body = "
                                Evalyn Gates is a theoretical physicist and author who has spent most of her research career deep in the cosmos, happily searching for dark matter, dark energy, neutrinos and white dwarfs. She is also passionate about inviting every curious mind on planet Earth to explore the natural world around us, from our own backyards to the most distant galaxies -- and beyond. Her first book, Einstein’s Telescope: The Hunt for Dark Matter and Dark Energy in the Universe, was published by W.W. Norton in February 2009.
                                Her trek through the academic world began at the College of William & Mary where she did her undergraduate work in physics, followed by an interesting detour through biomedical engineering. Unable to resist the call of the subatomic world, she dove back into physics -- earning her Ph.D. at CWRU before moving on to postdoctoral fellowships at Yale University and the University of Chicago, where she eventually joined the research faculty in the Department of Astronomy & Astrophysics and the Kavli Institute for Cosmological Physics.
                                Gates has served as the Assistant Director of the Kavli Institute for Cosmological Physics at the University of Chicago; Executive Director & CEO of the Cleveland Museum of Natural History; and Vice President for Science & Education at Chicago's Adler Planetarium & Astronomy Museum. She is currently an Adjunct Professor of Physics at CWRU and serving on several committees and advisory panels working to address the underrepresentation of women and minorities in science, recognizing that we are missing the ideas, energy and creativity of too many talented individuals who should be encouraged and supported.
                                Evalyn and her husband Eric have three grown children and live in the Rocky Mountains of Colorado where they spend as much time as possible hiking, skiing and exploring this incredible wilderness. She has summited 9 of Colorado's 14ers ... and counting."
                            />

                            
                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {kristina}
                            linkedin="https://www.linkedin.com/in/kristinakruglyak/"
                            name = "Kristina Kruglyak"
                            subheading = "Consultant, Self-Employed"
                            body = ""/> 

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {true} 
                            image = {philips_headshot}
                            name = "Julia M. Phillips"
                            linkedin="https://www.linkedin.com/in/julia-phillips-8b08316/"
                            subheading = "Executive Emeritus, Sandia National Laboratories, Retired"
                            body = "
                                Julia M. Phillips retired from Sandia National Laboratories in 2015. She culminated her Sandia career by serving as Vice President and Chief Technology Officer. Prior to her time at Sandia, she spent 14 years at AT&T Bell Laboratories where she performed leading edge research in thin film epitaxial electronic materials and complex oxides. She received a B.S. degree in physics from the College of William and Mary and a Ph.D. in applied physics from Yale University. Phillips is a member of the National Academy of Engineering (NAE) and fellow of the American Academy of Arts and Sciences, Materials Research Society, American Association for the Advancement of Science (AAAS), and the American Physical Society (APS). She received the George E. Pake Prize from APS “for her leadership and pioneering research in materials physics for industrial and national security applications.” She has authored more than 100 journal publications and holds five patents. Phillips was appointed a member of the National Science Board in 2016 and reappointed to the class of 2022-2028."
                            />

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {holly}
                            linkedin="https://www.linkedin.com/in/holly-szumila-vance-6b815b126/"
                            name = "Holly Szumila-Vance"
                            subheading = "Hall A/C Staff Scientist, Jefferson Lab"
                            body = ""/> 

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {karen}
                            linkedin="https://www.linkedin.com/in/karen-taminger-5920761/"
                            name = "Karen Taminger"
                            subheading = "Materials Research Engineer, NASA Langley Research Center"
                            body = ""/> 

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {true} 
                            image = {amber}
                            linkedin="https://www.linkedin.com/in/amber-ulbrich-046a2b38/"
                            name = "Amber Ulbrich"
                            subheading = "Physicist, Quantum Technologies, Department of Defense"
                            body = "Dr. Ulbrich is an analyst studying quantum
                            information science and technologies with the
                            Department of Defense, with two decades of
                            involvement in the field. She has worked in a
                            variety of positions, including Senior Intelligence
                            Officer for Technologies and Associate Chief
                            Scientist, and as the Army representative to the
                            Scientific and Technical Intelligence Committee (STIC). Her distinctions
                            include the Exceptional Performance Award, signed by Ambassador
                            Negroponte, Director of National Intelligence; the Joan A. Dempsey Award
                            for Mentorship under the Intelligence and National Security Alliance;
                            Civilian of the Year Award and Commander’s Award for Civilian Service; and
                            three Distinguished Analysis Awards from the Deputy Director of National
                            Intelligence for Analysis. She was a contributing author for the October 2021
                            White House report The Role of International Talent in Quantum Information
                            Science (posted at www.quantum.gov).
                            Dr. Ulbrich graduated magna cum laude with a Bachelor of Science in
                            physics with a minor in mathematics from the College of William and Mary
                            and received a Doctoral degree in physics from the University of Virginia. She
                            has professional certifications in Quantum Computing Fundamentals (MIT),
                            Quantum Computing Realities (MIT), and Machine Learning (Stanford) and
                            is proficient in German and Spanish.
                            In her free time, Amber enjoys spending time with her family and performing
                            locally as a singer, piano player, and songwriter with her band Three To Go
                            (ThreeToGo on Facebook). She has two albums of original music released
                            under prior band Burley Maple and one under Take Two."/> 

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            linkedin = "https://www.linkedin.com/in/lizward/"
                            image = {ward_headshot}
                            name = "Liz Ward"
                            subheading = "Principle Investigator, NASA Langley Research Center x CNU"
                            body = ""/> 


                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {true} 
                            image = {aye}
                            linkedin="https://www.linkedin.com/in/aye-myat-khine-win-2412236b/?originalSubdomain=sg"
                            name = "Aye Myat Khine Win"
                            subheading = "Senior Engineer, Micron Technology"
                            body = "Win was born and raised in Yangon, Myanmar. Currently living in Singapore since 2007. Received B.Eng degree in Engineering Product Development (Magna Cum Laude) specialized in Mechanical Engineering Track from Singapore University of Technology and Design (Singapore, Singapore). I am currently working on Environmental Sustainability Emission and cost savings programs. Apart from work, I like to practice yoga. I also enjoy learning new language. Currently I can speak 4 languages (Myanmar, English, Mandarin, and Japanese). I like traveling and had been to a few countries. All these experiences make me able to adapt easily and also I could share my life journey with my mentees."/>
                            
                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {xu_headshot}
                            linkedin="https://www.linkedin.com/in/yang-xu-205459b4/"
                            name = "Yang Xu"
                            subheading = "Senior Research Scientist, Luna Innovations"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {marlynAndino}
                            linkedin="https://www.linkedin.com/in/marlynandino/"
                            name = "Marlyn Andino"
                            subheading = "Research Aerospace Engineer, NASA Langley Research Center"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Irina Winter Arboleda"
                            subheading = "Internal Model Auditor, Fannie Mae"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {vanessaAubuchon}
                            linkedin="https://www.linkedin.com/in/vanessa-aubuchon-a98b4b54/"
                            name = "Vanessa Aubuchon"
                            subheading = "Associate Project Manager, NASA Langley Research Center"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {kristenBishop}
                            linkedin="https://www.linkedin.com/in/kristen-bishop-41468b78/"
                            name = "Kristen Bishop"
                            subheading = "Research Staff Member, Institute for Defense Analysis"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {brandiClark}
                            linkedin="https://www.linkedin.com/in/clarkbrandi/"
                            name = "Brandi Clark"
                            subheading = "R&D Manager, Savannah River National Laboratory"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            linkedin="https://www.linkedin.com/in/karinacolotl/"
                            name = "Karina Colotl"
                            subheading = "Process Integration Engineer, Micron Technology"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            linkedin="https://www.linkedin.com/in/jill-dahlburg-a4a6088/"
                            name = "Jill Dahlburg"
                            subheading = "Member/Writer, NASA Mission Critical Workforce, Infrastructure, and Technology/Self-Employed"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            linkedin="https://www.linkedin.com/in/julissa-de-guzman-3808b4b1/"
                            name = "Julissa de Guzman"
                            subheading = "Senior Software Engineer, BAE Systems"
                            body = ""/>

                            {/* <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Emily Fedroff"
                            subheading = "U.S. Army Corps of Engineers"
                            body = ""/>   */}

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            linkedin="https://www.linkedin.com/in/barbara-bendkowski-hilton-22925a85/"
                            name = "Barbara Hilton"
                            subheading = "Program Executive for the Planetary Science Division, NASA HQ Science Mission Directorate"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Jennifer Hite"
                            subheading = "Senior Materials Research Engineer, US Naval Research Laboratory"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {jenniferInman}
                            linkedin="https://www.linkedin.com/in/jennifer-inman-2ba300276/"
                            name = "Jennifer Inman"
                            subheading = "SCIFLI Project Manager, NASA Langley Research Center"
                            body = ""/>  

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {gabrielleJawer}
                            linkedin="https://www.linkedin.com/in/gabrielle-jawer-760a40142/"
                            name = "Gabrielle Jawer"
                            subheading = "Ting Operations Engineer, Whisker Labs"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            linkedin="https://www.linkedin.com/in/hee-yeon-keil-2a4465116/"
                            name = "Hee Yeon Keil"
                            subheading = "Mechanical Engineer, Athena Theatrical"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            linkedin="https://www.linkedin.com/in/tammi-klaco-761a43b/"
                            name = "Tammi Klaco"
                            subheading = "Independent Consultant, Klaco Ventures - Freelance"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {mystiqueOwens}
                            linkedin="https://www.linkedin.com/in/renee-mystique-owens/"
                            name = "Mystique Owens"
                            subheading = "Mechanical Engineer I, Psionic Technologies"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {sanghwaPark}
                            linkedin="https://www.linkedin.com/in/sanghwa-park-2a71a7bb/"
                            name = "Sanghwa Park"
                            subheading = "Staff Scientist, Jefferson Lab"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {sandraPhilpott}
                            linkedin="https://www.linkedin.com/in/sandra-philpott-43690b1b/"
                            name = "Sandra Philpott"
                            subheading = "Computer Scientist, Jefferson Lab"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "LeAnn Raczkowski"
                            subheading = "Program Engineer Manager, BAE Systems"
                            body = ""/>

                                <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {carrieRhoades}
                            linkedin="https://www.linkedin.com/in/planepanther/"
                            name = "Carrie Rhoades"
                            subheading = "Program Director, Advanced Solutions, Calspan"
                            body = ""/>

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            linkedin="https://www.linkedin.com/in/laura-treider/"
                            name = "Laura Treider"
                            subheading = "PYE Lead Senior Engineer, Micron Technology"
                            body = ""/>

                            
                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {brendaVanMill}
                            linkedin="https://www.linkedin.com/in/brenda-vanmil-0022a436/"
                            name = "Brenda VanMil"
                            subheading = "Physicist, US Army Research Laboratory"
                            body = ""/>
                            

                            <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Charlene Zhang"
                            subheading = "Senior Software Engineer, Microsoft"
                            body = ""/>
                             
             
                         

                    {/* <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Emily Parrish"
                            subheading = "Institute for Defense Analyses"
                            body = ""/>   */}

                    {/* <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Marisa Coler"
                            subheading = "ivWatch"
                            body = ""/>    */}

                    {/* <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Rachel Frazier"
                            subheading = "W&M"
                            body = ""/>  */}

                    {/* <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Hannah Oliver"
                            subheading = "Aurora Flight Sciences/Boeing"
                            body = ""/>     */}

                    {/* <BioPopup 
                            cardStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            slideupStyles = {{
                                backgroundColor: "rgb(255, 255, 250)"
                            }}
                            width = "220px"
                            height = "400px"
                            longBio = {false} 
                            hasBio = {false}
                            image = {blank_profile}
                            name = "Jing Ye"
                            subheading = "TikTok"
                            body = ""/> */}
            
                    
            </div>  
            <div className = "friendspage-header">
                Related <u>Companies</u>
            </div>
            <div className="friendspage-headshots-wrapper">
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={arcfield}
                    linkedin='https://www.arcfield.com/'
                    name="Arcfield"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={athena}
                    linkedin='https://www.athenatheatrical.com/'
                    name="Athena Theatrical"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={bae}
                    linkedin="https://www.baesystems.com/en/home"
                    name="BAE Systems"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={calspan}
                    linkedin="https://calspan.com/"
                    name="Calspan"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={case_western}
                    linkedin="https://case.edu/"
                    name="Case Western University"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={defense_analysis}
                    linkedin="https://www.ida.org/"
                    name="Institute for Defense Analysis"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={devcom}
                    linkedin="https://arl.devcom.army.mil/"
                    name="U.S. Army Research Laboratory"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={dod}
                    linkedin="https://www.defense.gov/"
                    name="Department of Defense"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={fanniemae}
                    linkedin="https://www.fanniemae.com/"
                    name="Fannie Mae"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={honeywell}
                    linkedin="https://www.honeywell.com/us/en"
                    name="Honeywell"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={ivwatch}
                    linkedin='https://www.ivwatch.com/'
                    name="ivWatch"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={jefferson_lab}
                    linkedin="https://www.jlab.org/"
                    name="Jefferson Lab"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={lunalabs}
                    linkedin="https://lunalabs.us/"
                    name="Luna Labs"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={micron}
                    linkedin="https://www.micron.com/"
                    name="Micron Technology"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={microsoft}
                    linkedin="https://www.microsoft.com/en-us/"
                    name="Microsoft"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={mitre}
                    linkedin="https://www.mitre.org/"
                    name="The MITRE Corp"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={nasa}
                    linkedin="https://www.nasa.gov/langley/"
                    name="Nasa Langley Research Center"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={nasa2}
                    linkedin="https://www.nasa.gov/"
                    name="NASA"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={naval_research_lab}
                    linkedin="https://www.nrl.navy.mil/"
                    name="US Naval Research Lab"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={procure}
                    linkedin="https://www.procure.com/"
                    name="ProCure Treatment Centers"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={psionic}
                    linkedin='https://www.psionicnav.com/'
                    name="Psionic Technologies"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={sandia}
                    linkedin='https://www.sandia.gov/'
                    name="Sandia National Lab"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={srnl}
                    linkedin='https://www.srnl.gov/'
                    name="Savannah River National Lab"
                    />
                <BioPopup
                    cardStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    slideupStyles={{
                        backgroundColor: "rgb(255, 255, 250)"
                    }}
                    width="220px"
                    height="400px"
                    longBio={false}
                    hasBio={false}
                    image={ting}
                    linkedin="https://www.whiskerlabs.com/"
                    name="TingFire, Whisker Labs"
                    />

            </div>
        </div>
    )
}

export default Mentors; 