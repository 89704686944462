import React, {Fragment, useEffect, useState} from 'react';
import { PortalAPI } from '../../api/portal';
import {getState, setState} from '../../state/StateManagement.js'; 
import {setCookie} from "../../helpers/SendRequests.js"
import Profile from '../components/Profile';
import Associate from "../components/Associate"; 
import Journal from "../components/Journal"; 
import News from "../../clearweb/pages/News"
import AdminFunctions from "../components/AdminFunctions/AdminFunctions"
import "./Dashboard.scss"
import ViewProfiles from '../components/ViewProfiles';

export default function Dashboard(){
    const TabState = Object.freeze({
        "JOURNAL":0, "PROFILE": 1, "ASSOCIATE":2, "PROFILES":3, "NEWS":4, "ADMIN_FUNCTIONS": 5}); 

    const [curTab, setCurTab] = useState(TabState.PROFILE)
    const [userFirstName, setUserFirstName] = useState("")
    const [userProfile, setUserProfile] = useState(null)

    function handleClick(e){ // hande when a user clicks a tab 
        setCurTab(e); 
    }

    useEffect(() => {
        //ONLOAD 
        async function checkUser(){ //check the user has logged in using cas before coming here 
            const response = await fetch("/api/v1/auth/checkcas")
            if(!response.ok){ // if the response is bad, then redirect the user to cas login 
                let redirectRoute = window.location.origin + "/portal/dashboard"
                let apiPort = ""
                if(process.env.REACT_APP_DEVELOPER_MODE !== undefined && process.env.REACT_APP_DEVELOPER_MODE === "True"){
                    apiPort = ":5050"
                }
                window.location.href = window.location.protocol + "//" + window.location.hostname + apiPort + "/api/v1/auth/login?redirectTo=" + redirectRoute
            }

            //make a request to get access tokens from the API 
            const aresponse = await fetch("/api/v1/auth/getAccessToken")
            if(aresponse.status === 401){
                window.location.href = "/portal/login"
            }
            if(aresponse.status === 402){ //user does not exist --> send them to the register page 
                window.location.href = "/portal/register"
            }
            const aresult = await aresponse.json()
            setCookie("accessToken", aresult['accessToken'], 10);
            setCookie("refreshToken", aresult['refreshToken'], 10);
            setState("USERNAME", aresult['username']);
    
            //make api call to get profile information
            const portalAPI = new PortalAPI(); 
            const [status, user_profile] = await portalAPI.getUser(getState("USERNAME"));  //array unpacking in ES6
            if(status === 200){
                setUserFirstName(user_profile.firstName)
                setUserProfile(user_profile)
            }else{
                //forward the user back to the login screen 
                window.location.href = "/portal/login"
    
            }
        }
        checkUser() 

    }, [userFirstName])

    return (
        <Fragment> 
            <div style = {{height: "70px"}}></div>
            
            <div className = "portal-dashboard-wrapper">
                <div className = "portal-tab-selector-wrapper">
                
                    <div>
                    <p className = {curTab === TabState.PROFILE ? "portal-tab-open": "portal-tab-closed"} onClick = {() => handleClick(TabState.PROFILE)}>Your Profile</p>
                    <div className = {curTab === TabState.PROFILE ? "portal-tab-underline-open": "portal-tab-underline-closed"}></div>
                    </div>

                    <div>
                    <p className = {curTab === TabState.JOURNAL ? "portal-tab-open": "portal-tab-closed"} onClick = {() => handleClick(TabState.JOURNAL)}
                    style = {{color:'grey'}}
                    >Journal</p> 
                    <div className = {curTab === TabState.JOURNAL ? "portal-tab-underline-open": "portal-tab-underline-closed"}></div>
                    </div>

                    <div>
                    <p className = {curTab === TabState.ASSOCIATE ? "portal-tab-open": "portal-tab-closed"} onClick = {() => handleClick(TabState.ASSOCIATE)}
                    >{userProfile && (userProfile.status === "MENTEE" || userProfile.status === "MENTEE_PENDING") ? "Your Mentor" : "Your Mentee"}</p>
                    <div className = {curTab === TabState.ASSOCIATE ? "portal-tab-underline-open": "portal-tab-underline-closed"}></div>
                    </div>

                    <div>
                    <p className = {curTab === TabState.PROFILES ? "portal-tab-open": "portal-tab-closed"} onClick = {() => handleClick(TabState.PROFILES)}
                    >View Profiles</p> 
                    <div className = {curTab === TabState.PROFILES ? "portal-tab-underline-open": "portal-tab-underline-closed"}></div>
                    </div> 

                    <div>
                    <p className = {curTab === TabState.NEWS ? "portal-tab-open": "portal-tab-closed"} onClick = {() => handleClick(TabState.NEWS)}>News</p>
                    <div className = {curTab === TabState.NEWS ? "portal-tab-underline-open": "portal-tab-underline-closed"}></div>
                    </div>

                    {userProfile && userProfile.status === "SUPERADMIN" 
                    ? 
                        <div>
                            <p className = { 
                                curTab === TabState.AdminFunctions? 
                                 "portal-tab-open": "portal-tab-closed"
                            } onClick = {() => handleClick(TabState.ADMIN_FUNCTIONS
                                )}>Admin Functions</p>
                            <div className = {curTab === TabState.ADMIN_FUNCTIONS? "portal-tab-underline-open": "portal-tab-underline-closed"}></div>
                        </div> 
                    : null }
                    
                    
                </div>
                <div className = "portal-hello-header">
                    <div className = "portal-user-logo">{userFirstName[0]}</div>
                    <div className = "portal-user-name">{userFirstName}</div>
                </div> 
                
                <div className = "protal-selected-tab-wrapper">
                    {/* Choose which is currently selected via tabs and display here  */}

                    {curTab === TabState.PROFILE && userProfile? <Profile profile = {userProfile}/> : null }
                    {curTab === TabState.ASSOCIATE && userProfile? <Associate profile = {userProfile}/> : null }
                    {curTab === TabState.JOURNAL? <Journal/> : null }
                    {curTab === TabState.PROFILES? <ViewProfiles/> : null }
                    {curTab === TabState.NEWS? <News portal={true}/> : null }
                    {curTab === TabState.ADMIN_FUNCTIONS? <AdminFunctions/> : null }

                    
                </div>
            </div>
        </Fragment> 
    )
}