import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  //Link,
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { setState } from './state/StateManagement.js';

import Navbar from './components/navibar.js';
import Home from './clearweb/pages/Homepage';
import News from './clearweb/pages/News';
import Mentors from './clearweb/pages/Mentors';
import RetiredMembers from "./clearweb/pages/RetiredMembers";
import Mentees from "./clearweb/pages/Mentees";
import Contact from './clearweb/pages/Contact';
import PrivacyPolicy from './clearweb/pages/PrivacyPolicy';
import Events from './clearweb/pages/Events.js';

//admin -- Depreciated - moved to backend server using adminJS
// import AdminLogin from './admin/AdminLogin.js'; 
// import AdminPortal from './admin/AdminPortal.js'; 
//Poral
import Dashboard from './portal/pages/Dashboard';
import PortalLogin from './portal/pages/Login';
import Register from './portal/pages/Register';
import InterestRedirect from "./interest/pages/Redirect";

//App wide state to know if the user is in the portal or not 


function App() {

  useEffect(() => {
    setState("USER_STATE", "NONE");
  }, [])


  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/news" element={<News />}></Route>
          <Route path="/mentors" element={<Mentors />}></Route>
          <Route path="/mentees" element={<Mentees />}></Route>
          <Route path="/retired" element={<RetiredMembers />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          {/* Event Routes */}
          <Route path="/events/:eventName" element={<Events />}></Route>

          {/* allow these paths to set the user state and get the user state */}
          {/* <Route path = "/admin/login" element = {<AdminLogin />}></Route> 
          <Route path = "/admin/portal" element = {<AdminPortal />}></Route>       */}
          {/* Portal Routes */}
          <Route path="/portal/login" element={<PortalLogin />}></Route>
          <Route path="/portal/register" element={<Register />}></Route>
          <Route path="/portal/dashboard" element={<Dashboard />}></Route>


        </Routes>
      </Router>
    </div>
  );
}

export default App;
