import React, {Fragment} from 'react'; 
import "./Journal.scss"; 


export default function Journal(){
    return(
        <Fragment> 
            <div className = "journal-component-wrapper">
                <h1>Feature coming soon...</h1> 
            </div>
        </Fragment> 
    )
}